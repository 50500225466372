import {
  Datagrid,
  BooleanField,
  DateField,
  EmailField,
  Show,
  TextField,
  NumberField,
  ImageField,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
} from "react-admin";
import UserTitle from "./UserTitle";

const UserShow = (props: any) => (
  <Show {...props} title={<UserTitle />}>
    <TabbedShowLayout>
      <Tab label="Details">
        <ImageField source="photo" />
        <TextField source="id" />
        <TextField source="fullName" />
        <EmailField source="email" />
        <TextField source="type" />
        <TextField source="nationality" />
        <TextField source="lastLanguage" />
        <BooleanField source="isAdmin" />
        <BooleanField source="approved" />
        <TextField source="iban" />
        <NumberField
          source="credit"
          options={{ style: "currency", currency: "EUR" }}
        />
        <DateField source="termsVersion" />
        <TextField source="appVersion" />
        <TextField source="emailPreferences" />
        <TextField source="pushPreferences" />
        <DateField source="enteredDate" />
        <DateField source="lastModifiedDate" />
        <DateField source="lastModifiedBy" />
        <BooleanField source="isActive" />
      </Tab>
      <Tab label="Orders">
        <TextField source="orders" />
      </Tab>
      <Tab label="Billing">
        <TextField source="paymentMethods" />
        <ReferenceManyField
          label="Addresses"
          reference="realEstate"
          target="userId"
          sort={{ field: "enteredDate", order: "DESC" }}
        >
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="apartment" />
            <TextField source="street" />
            <TextField source="type" />
            <BooleanField source="isDefault" />
            <DateField source="enteredDate" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Transactions">
        <ReferenceManyField
          label="Payments"
          reference="transaction"
          target="userId"
          sort={{ field: "enteredDate", order: "DESC" }}
        >
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="status" />
            <NumberField
              source="amount"
              options={{ style: "currency", currency: "EUR" }}
            />
            <DateField source="paidDate" />
            <DateField source="escrowDate" />
            <DateField source="enteredDate" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default UserShow;
