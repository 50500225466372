import { Login } from "react-admin";
import { LoginForm } from "./PhoneLoginForm";

const LoginPage = () => (
  <Login
    backgroundImage="https://source.unsplash.com/random/1600x900/daily"
    children={<LoginForm />}
  />
);

export default LoginPage;
