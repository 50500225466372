import { Resource } from "react-admin";
import TransactionList from "./TransactionList";
import TransactionShow from "./TransactionShow";
import TransactionIcon from "@mui/icons-material/Euro";

export default (
  <Resource
    name="transaction"
    icon={TransactionIcon}
    list={TransactionList}
    show={TransactionShow}
  />
);
