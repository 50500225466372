import { useEffect, useCallback } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useSafeSetState } from "ra-core";
import { AllocationChart, DownloadChart } from "../components/AnalyticsCharts";

const ANALYTICS_URL =
  "https://cleanify-staging.azurewebsites.net/api/v2/admin/analytics/";

const Dashboard = () => {
  const [analytics, setAnalytics] = useSafeSetState(null);

  const loadAnalytics = useCallback((): void => {
    const token: any = localStorage.getItem("auth");
    fetch(`${ANALYTICS_URL}conversions`, {
      headers: {
        "x-auth": `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Data) {
          throw new Error(data.Data.Message);
        }
        if (data.data && data.Message) {
          throw new Error(data.data.Message);
        }
        console.log(data);
        setAnalytics(data.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [setAnalytics]);

  useEffect(() => {
    loadAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card sx={{ marginTop: 1 }}>
      <CardHeader title="Welcome to the Cleanify Administration Dashboard - WIP" />
      <CardContent>
        Downloads: [{analytics ? "-" : "-"}]
        <br />
        <Card sx={{ width: 400 }}>
          <AllocationChart />
          <DownloadChart />
        </Card>
      </CardContent>
    </Card>
  );
};

export default Dashboard;
