import {
  BooleanField,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export default (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="street" />
      <TextField source="apartment" />
      <TextField source="type" />
      <BooleanField source="isDefault" />
      <ReferenceField source="userId" reference="users" link="show">
        <TextField source="fullName" />
      </ReferenceField>
      <DateField source="enteredDate" />
      <DateField source="enteredBy" />
      <DateField source="lastModifiedDate" />
      <DateField source="lastModifiedBy" />
      <BooleanField source="isActive" />
    </SimpleShowLayout>
  </Show>
);
