import {
  BooleanField,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export default (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <NumberField
        source="price"
        options={{ style: "currency", currency: "EUR" }}
      />
      <TextField source="comment" />
      <TextField source="status" />
      <ReferenceField source="orderId" reference="orders" link="show">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="cleanerId" reference="users" link="show">
        <TextField source="fullName" />
      </ReferenceField>
      <DateField source="enteredDate" />
      <TextField source="enteredBy" />
      <DateField source="lastModifiedDate" />
      <TextField source="lastModifiedBy" />
      <BooleanField source="isActive" />
    </SimpleShowLayout>
  </Show>
);
