import {
  BooleanField,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export default (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="escrowDate" />
      <DateField source="paidDate" />
      <NumberField source="status" />
      <NumberField
        source="creditUsed"
        options={{ style: "currency", currency: "EUR" }}
      />
      <NumberField
        source="amount"
        options={{ style: "currency", currency: "EUR" }}
      />
      <NumberField
        source="tax"
        options={{ style: "currency", currency: "EUR" }}
      />
      <NumberField
        source="surcharge"
        options={{ style: "currency", currency: "EUR" }}
      />
      <NumberField
        source="total"
        options={{ style: "currency", currency: "EUR" }}
      />
      <TextField source="paymentReference" />
      <TextField source="creditReference" />
      <ReferenceField source="clientId" reference="users" link="show">
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField source="cleanerId" reference="users" link="show">
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField source="orderId" reference="orders" link="show">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="paymentMethodId" />
      <DateField source="enteredDate" />
      <DateField source="enteredBy" />
      <DateField source="lastModifiedBy" />
      <BooleanField source="isActive" />
    </SimpleShowLayout>
  </Show>
);
