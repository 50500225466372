import { AU, EE, RU } from "country-flag-icons/react/3x2";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const countries: any = [
  {
    label: "Australia",
    src: AU,
    link: " ",
    value: "AU",
  },
  {
    label: "Estonia",
    src: EE,
    link: " ",
    value: "EE",
  },
  {
    label: "Russia",
    src: RU,
    link: " ",
    value: "RU",
  },
];

const classes = {
  countryItem: {
    paddingTop: 1,
    "& .countryFlag": {
      height: 30,
      verticalAlign: "middle",
    },
    "& .countryText": {
      //paddingLeft: 2,
      paddingLeft: 8,
      verticalAlign: "middle",
    },
    "& .MuiSelect-select": {
      paddingTop: 1.5,
      paddingBottom: 1,
    },
    "&[class].MuiInputBase-root.MuiInputBase-formControl": {
      paddingTop: 0,
    },
  },
};

const CountryInput = (props: any) => (
  <FormControl fullWidth>
    <InputLabel htmlFor="open-select" sx={{ marginLeft: 7, marginTop: 1.7 }}>
      Country:
    </InputLabel>
    <Select
      {...props}
      sx={classes.countryItem}
      label="Country"
      variant="filled"
      inputProps={{
        id: "open-select",
      }}
    >
      {countries.map((option: any) => (
        <MenuItem
          value={option.value}
          key={option.value}
          sx={classes.countryItem}
        >
          <option.src title={option.label} className="countryFlag" />
          <span className="countryText">{option.label}</span>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default CountryInput;
