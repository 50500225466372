import {
  List,
  Pagination,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  DateField,
  TextInput,
  SelectInput,
  EditButton,
} from "react-admin";
import UserTypes from "./UserTypes";

const userFilters = [
  <TextInput source="q" label="Search" alwaysOn resettable />,
  <SelectInput source="type" choices={UserTypes} emptyText="All" />,
  <TextInput source="phoneNumber" />,
  <TextInput source="nationality" />,
];

const UserPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const UserList = (props: any) => (
  <List
    {...props}
    filters={userFilters}
    pagination={<UserPagination />}
    sort={{ field: "enteredDate", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="type" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="nationality" />
      <BooleanField source="approved" />
      <DateField source="enteredDate" />
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
