import { Admin, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

import authProvider from "./auth/authProvider";
import LoginPage from "./auth/LoginPage";
import AppLayout from "./dashboard/AppLayout";
import Dashboard from "./dashboard/Dashboard";
import UserResource from "./users/UserResource";
import OrderResource from "./orders/OrderResource";
import OfferResource from "./offer/OfferResource";
import RealEstateResource from "./realEstate/RealEstateResource";
import TransactionResource from "./transaction/TransactionResource";
import "./App.css";

const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token: any = localStorage.getItem("auth");
  options.headers.set("x-auth", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = simpleRestProvider(
  "https://cleanify-staging.azurewebsites.net/api/v2/admin",
  httpClient
);

function App() {
  return (
    <Admin
      layout={AppLayout}
      dashboard={Dashboard}
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
    >
      {UserResource}
      {OrderResource}
      {OfferResource}
      {RealEstateResource}
      {TransactionResource}
    </Admin>
  );
}

export default App;
