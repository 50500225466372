import {
  BooleanInput,
  DateInput,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
} from "react-admin";
import UserTitle from "./UserTitle";

const UserEdit = (props: any) => (
  <Edit {...props} title={<UserTitle />}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="phoneNumber" />
      <TextInput source="lastLanguage" />
      <TextInput source="nationality" />
      <BooleanInput source="isAdmin" />
      <BooleanInput source="approved" />
      <NumberInput source="credit" />
      <TextInput source="iban" />
      <TextInput source="photo" />
      <DateInput source="termsVersion" />
      <TextInput source="appVersion" />
      <TextInput source="emailPreferences" />
      <TextInput source="pushPreferences" />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
