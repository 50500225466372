import {
  List,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  SelectInput,
  NumberInput,
} from "react-admin";
import TransactionStatus from "./TransactionStatus";

const transactionFilters = [
  <SelectInput
    source="status"
    choices={TransactionStatus}
    emptyText="All"
    alwaysOn
  />,
  <NumberInput source="amountMin" />,
  <NumberInput source="amountMax" />,
];

const TransactionPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

export default (props: any) => (
  <List
    {...props}
    filters={transactionFilters}
    pagination={<TransactionPagination />}
    sort={{ field: "enteredDate", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="status" />
      <NumberField
        source="amount"
        options={{ style: "currency", currency: "EUR" }}
      />
      <DateField source="paidDate" />
      <DateField source="escrowDate" />
      <DateField source="enteredDate" />
    </Datagrid>
  </List>
);
