import {
  List,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  TextInput,
  SelectInput,
  NullableBooleanInput,
} from "react-admin";
import RealEstateTypes from "./RealEstateTypes";

const RealEstateFilters = [
  <TextInput source="q" label="Search" alwaysOn resettable />,
  <NullableBooleanInput source="isDefault" nullLabel="Either" alwaysOn />,
  <SelectInput source="type" choices={RealEstateTypes} emptyText="All" />,
];

const RealEstatePagination = (props: any) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

export default (props: any) => (
  <List
    {...props}
    filters={RealEstateFilters}
    pagination={<RealEstatePagination />}
    sort={{ field: "enteredDate", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="apartment" />
      <TextField source="street" />
      <TextField source="type" />
      <BooleanField source="isDefault" />
      <DateField source="enteredDate" />
    </Datagrid>
  </List>
);
