import { TextInput } from "react-admin";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Button,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Form, required, useLogin, useNotify, useSafeSetState } from "ra-core";
import CountryInput from "../components/CountryInput";

const LOGIN_URL =
  "https://cleanify-staging.azurewebsites.net/api/v2/Users/login-admin";
const VERIFY_URL =
  "https://cleanify-staging.azurewebsites.net/api/v2/Users/login";

export const LoginForm = (props: LoginFormProps) => {
  const { redirectTo, className } = props;

  const [loading, setLoading] = useSafeSetState(false);
  const [verifying, setVerifying] = useSafeSetState(false);
  const [country, setCountry] = useSafeSetState("EE");
  const [sessionId, setSessionId] = useSafeSetState("");

  const login = useLogin();
  const notify = useNotify();

  const submit = (values: any) => {
    setLoading(true);

    if (verifying) {
      fetch(VERIFY_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          SessionId: sessionId,
          Code: values.code,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.Data) {
            throw new Error(data.Data.Message);
          }
          if (data.data && data.Message) {
            throw new Error(data.data.Message);
          }
          if (!data.data.admin) {
            throw new Error("User is not an administrator!");
          }
          console.log("Success");
          console.log(data);
          login(
            {
              username: data.data.fullName,
              token: data.data.access_token,
              avatar: data.data.photo,
            },
            redirectTo
          )
            .then(() => {
              setLoading(false);
            })
            .catch((error) => {
              setVerifying(false);
              setLoading(false);
              notify(
                typeof error === "string"
                  ? error
                  : typeof error === "undefined" || !error.message
                  ? "ra.auth.sign_in_error"
                  : error.message,
                {
                  type: "warning",
                  messageArgs: {
                    _:
                      typeof error === "string"
                        ? error
                        : error && error.message
                        ? error.message
                        : undefined,
                  },
                }
              );
            });
        })
        .catch((error) => {
          setVerifying(false);
          setLoading(false);
          notify(
            typeof error === "string"
              ? error
              : typeof error === "undefined" || !error.message
              ? "ra.auth.sign_in_error"
              : error.message,
            {
              type: "warning",
              messageArgs: {
                _:
                  typeof error === "string"
                    ? error
                    : error && error.message
                    ? error.message
                    : undefined,
              },
            }
          );
        });
    } else {
      fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Username: values.username,
          Country: country,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.Data) {
            throw new Error(data.Data.Message);
          }
          if (data.data && data.Message) {
            throw new Error(data.data.Message);
          }

          setSessionId(data.data);
          setVerifying(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          notify(
            typeof error === "string"
              ? error
              : typeof error === "undefined" || !error.message
              ? "ra.auth.sign_in_error"
              : error.message,
            {
              type: "warning",
              messageArgs: {
                _:
                  typeof error === "string"
                    ? error
                    : error && error.message
                    ? error.message
                    : undefined,
              },
            }
          );
        });
    }
  };

  const countryChange = (event: any) => {
    setCountry(event.target.value);
  };

  return (
    <StyledForm
      onSubmit={submit}
      mode="onChange"
      noValidate
      className={className}
    >
      <CardContent className={LoginFormClasses.content}>
        <Typography
          variant="h5"
          component="div"
          sx={{ textAlign: "center", marginTop: -2, marginBottom: 2 }}
        >
          {verifying ? "Enter Code" : "Sign In"}
        </Typography>
        <CountryInput fullWidth onChange={countryChange} value={country} />
        <TextInput
          autoFocus
          source="username"
          label="Phone Number"
          validate={required()}
          fullWidth
        />
        <TextInput
          source="code"
          label="Verification Code"
          type="password"
          fullWidth
          disabled={!verifying}
          validate={verifying ? required() : undefined}
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress
              className={LoginFormClasses.icon}
              size={19}
              thickness={3}
            />
          ) : (
            "Continue"
          )}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = "RaLoginForm";

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface LoginFormProps {
  redirectTo?: string;
  className?: string;
}

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};
