import { Resource } from "react-admin";
import RealEstateList from "./RealEstateList";
import RealEstateShow from "./RealEstateShow";
import RealEstateIcon from "@mui/icons-material/Home";

export default (
  <Resource
    name="realEstate"
    icon={RealEstateIcon}
    list={RealEstateList}
    show={RealEstateShow}
  />
);
