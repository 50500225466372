import { Resource } from "react-admin";
import UserList from "./UserList";
import UserShow from "./UserShow";
import UserEdit from "./UserEdit";
import UserIcon from "@mui/icons-material/Group";

const UserResource = (
  <Resource
    name="users"
    icon={UserIcon}
    list={UserList}
    show={UserShow}
    edit={UserEdit}
  />
);

export default UserResource;
