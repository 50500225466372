import {
  defaultTheme,
  ToggleThemeButton,
  AppBar,
  Layout,
  Logout,
  UserMenu,
} from "react-admin";
import { createTheme, Box, Typography, Avatar, MenuItem } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const AvatarIcon = () => {
  const photo = localStorage.getItem("avatar");
  return (
    <Avatar
      sx={{
        height: 30,
        width: 30,
      }}
      src={photo ? photo : undefined}
    />
  );
};

const AppUserMenu = (props: any) => {
  const username = localStorage.getItem("username");
  return (
    <UserMenu {...props} icon={<AvatarIcon />}>
      <MenuItem>
        <Typography variant="body2">{username}</Typography>
      </MenuItem>
      <Logout />
    </UserMenu>
  );
};

const CleanifyAppBar = (props: any) => (
  <AppBar {...props} userMenu={<AppUserMenu />}>
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
    <ToggleThemeButton lightTheme={defaultTheme} darkTheme={darkTheme} />
  </AppBar>
);

const AppLayout = (props: any) => <Layout {...props} appBar={CleanifyAppBar} />;

export default AppLayout;
