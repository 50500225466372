export default {
  // called when the user attempts to log in
  login: ({ username, token, avatar }: any) => {
    localStorage.setItem("username", username);
    localStorage.setItem("auth", token);
    localStorage.setItem("avatar", avatar);
    // accept all username/password combinations
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("username");
    localStorage.removeItem("auth");
    localStorage.removeItem("avatar");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }: any) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("username");
      localStorage.removeItem("auth");
      localStorage.removeItem("avatar");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
