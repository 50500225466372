import {
  BooleanField,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  TextField,
  Datagrid,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
} from "react-admin";

export default (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="id" />
        <TextField source="status" />
        <NumberField
          source="price"
          options={{ style: "currency", currency: "EUR" }}
        />
        <DateField source="orderDate" />
        <BooleanField source="needSuplies" />
        <BooleanField source="needTools" />
        <DateField source="comment" />
        <BooleanField source="visibleToAllCleaners" />
        <ReferenceField
          source="realEstateId"
          reference="realEstate"
          link="show"
        >
          <TextField source="street" />
        </ReferenceField>
        <TextField source="invitedCleaners" />
        <TextField source="cleanerHideList" />
        <DateField source="enteredDate" />
        <DateField source="lastModifiedDate" />
        <BooleanField source="isActive" />
      </Tab>
      <Tab label="Offers">
        <ReferenceManyField
          label="Offers"
          reference="offer"
          target="orderId"
          sort={{ field: "enteredDate", order: "DESC" }}
        >
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="status" />
            <NumberField
              source="price"
              options={{ style: "currency", currency: "EUR" }}
            />
            <DateField source="responseDate" />
            <DateField source="enteredDate" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Services">
        <TextField source="packageId" />
        <TextField source="additionalService" />
        <TextField source="additionalServices" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
